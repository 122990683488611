import { React, useState} from 'react'
import './AppWrapper.css';
import { Routes, Route, Outlet, NavLink } from "react-router-dom";
import {
  SidebarPusher,
  SidebarPushable,
  MenuItem,
  GridColumn,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import Home from '../Home/Home'
import Services from '../Services/Services';
import Portfolio from '../Portfolio/Portfolio';



const AppWrapper = (children) => {
  const [visible, setVisible] = useState(false)
  const [activeItem,setactiveItem]=useState("home");
  const handleItemClick = (e, { name }) => {
    setactiveItem(name)
    setVisible(!setVisible)
  };

  return (
      <Segment style={{ padding: '0em' }} vertical>
        <Menu borderless={true} color='red' id='topMenu'>
          <MenuItem
            as='a'
            name='menuToggle'
            onClick={(e, data) => setVisible(true)}
          >
            <Icon name='bars' size='large' />
          </MenuItem>
          <MenuItem
            as='a'
            header
            id='logo-button'
            style={{ padding: 0}}
            name='home'
          >
            <Image size='small' src='/images/logo-horiz.svg' style={{ margin: '0 1em', height: '3rem', padding: 0}} />
          </MenuItem>
        </Menu>

        <GridColumn id='contentColumn'>
          <SidebarPushable>
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              inverted
              onHide={() => setVisible(false)}
              vertical
              visible={visible}
              width='thin'
            >
                <MenuItem
                  as={NavLink}
                  to='/'
                  name='home'
                  active={activeItem === 'home'}
                  onClick={handleItemClick}
                  >
                  <Icon name='home' />
                  Home
                </MenuItem>
                <MenuItem
                  as={NavLink}
                  to='/services'
                  name='services'
                  active={activeItem === 'services'}
                  onClick={handleItemClick}
                >
                  <Icon name='list' />
                  Services
                </MenuItem>
              <MenuItem
                as={NavLink}
                to='/projects'
                name='projects'
                active={activeItem === 'projects'}
                onClick={handleItemClick}
              >
                <Icon name='folder open' />
                Projects
              </MenuItem>
              <MenuItem
                name='contact'
                active={activeItem === 'contact'}
                onClick={handleItemClick}
              >
                <Icon name='comments' />
                Contact
              </MenuItem>
            </Sidebar>
            <SidebarPusher>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route index element={<Home />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/projects" element={<Portfolio />} />
                  <Route path="*" element={<Home />} />
              </Routes>
              <Outlet />
            </SidebarPusher>
          </SidebarPushable>
        </GridColumn>
      </Segment>
  )
}

export default AppWrapper